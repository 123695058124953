import { ReactNode, useCallback, useMemo, useState } from "react";

import { FieldType } from "components/common/form/FieldWrapper";
import { Trans } from "@lingui/macro";

interface ValidationRules {
    disabled?: boolean;

    required?: boolean;

    minLength?: number;
    maxLength?: number;

    minValue?: number | Date;
    maxValue?: number | Date;
    minMaxValueExclusive?: boolean;

    pattern?: RegExp;
    patternMessage?: ReactNode;

    custom?: (name: string, value: any) => ReactNode;
}

interface Field {
    name: string;
    type: FieldType;
    value: any;
    validationRules: ValidationRules;
}

interface FieldError {
    rule: "required" | "length" | "value" | "pattern" | "custom";
    message: ReactNode;
}

interface FormErrors {
    [index: string]: FieldError[] | undefined;
}

export interface FormValidator {
    setErrorMode: (mode: "single" | "multiple") => void;

    registerField: (name: string, type: FieldType, initialValue: any, validationRules: ValidationRules) => void;
    unregisterField: (name: string) => void;

    reset: () => void;

    validate: () => boolean;

    fieldFocused: (name: string) => void;
    fieldBlurred: (name: string) => void;
    fieldUpdated: (name: string, value: any) => void;

    getErrors: (name: string) => FieldError[];
}

/** Hook implementation. */
export const useFormValidator = (initialErrorMode?: "single" | "multiple") => {
    const [errorMode, setErrorMode] = useState<"single" | "multiple">(initialErrorMode || "single");
    const [fields, setFields] = useState<Field[]>([]);
    const [previouslyValidated, setPreviouslyValidated] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    const validateField = useCallback(
        (field: Field): FieldError[] => {
            const { name, type, value, validationRules } = field;
            const { disabled, required, minLength, maxLength, minValue, maxValue, minMaxValueExclusive, pattern, patternMessage, custom } = validationRules;

            const errors: FieldError[] = [];

            if (!disabled) {
                // Handle standard validation rules.
                switch (type) {
                    case "text":
                    case "password":
                    case "textarea":
                    case "ace":
                    case "monaco":
                    case "masked":
                        if (required && (value == null || (value as string).trim().length === 0)) {
                            errors.push({ rule: "required", message: <Trans>Required</Trans> });

                            if (errorMode === "single") return errors;
                        }

                        if (value != null) {
                            if (minLength != null && maxLength != null) {
                                if (minLength === maxLength) {
                                    if ((value as string).trim().length !== minLength) {
                                        errors.push({ rule: "length", message: <Trans>Must be {minLength} characters in length</Trans> });

                                        if (errorMode === "single") return errors;
                                    }
                                } else {
                                    if ((value as string).trim().length < minLength || (value as string).trim().length > maxLength) {
                                        errors.push({
                                            rule: "length",
                                            message: (
                                                <Trans>
                                                    Must be between {minLength} and {maxLength} characters in length
                                                </Trans>
                                            ),
                                        });

                                        if (errorMode === "single") return errors;
                                    }
                                }
                            } else {
                                if (minLength != null && (value as string).trim().length < minLength) {
                                    errors.push({ rule: "length", message: <Trans>Must be at least {minLength} characters in length</Trans> });

                                    if (errorMode === "single") return errors;
                                }

                                if (maxLength != null && (value as string).trim().length > maxLength) {
                                    errors.push({ rule: "length", message: <Trans>Must be at most {maxLength} characters in length</Trans> });

                                    if (errorMode === "single") return errors;
                                }
                            }

                            if (pattern != null && (value as string).length > 0 && !(value as string).match(pattern)) {
                                errors.push({ rule: "pattern", message: <Trans>Invalid: {patternMessage ? patternMessage : pattern.toString()}</Trans> });

                                if (errorMode === "single") return errors;
                            }
                        }

                        break;
                    case "select":
                        if (required && value == null) {
                            errors.push({ rule: "required", message: <Trans>Required</Trans> });

                            if (errorMode === "single") return errors;
                        }

                        break;
                    case "combobox":
                        if (required && value == null) {
                            errors.push({ rule: "required", message: <Trans>Required</Trans> });

                            if (errorMode === "single") return errors;
                        }

                        if (value != null) {
                            if (pattern != null && (value as string).length > 0 && !(value as string).match(pattern)) {
                                errors.push({ rule: "pattern", message: <Trans>Invalid: {patternMessage ? patternMessage : pattern.toString()}</Trans> });

                                if (errorMode === "single") return errors;
                            }
                        }

                        break;
                    case "number":
                        if (required && value == null) {
                            errors.push({ rule: "required", message: <Trans>Required</Trans> });

                            if (errorMode === "single") return errors;
                        }

                        const minValueAsNumber_number = minValue != null && typeof minValue === "number" ? minValue : null;
                        const maxValueAsNumber_number = maxValue != null && typeof maxValue === "number" ? maxValue : null;
                        const valueAsNumber_number = value != null && typeof value === "number" ? value : null;

                        if (valueAsNumber_number != null) {
                            if (minValueAsNumber_number != null && maxValueAsNumber_number != null) {
                                if (minValueAsNumber_number === maxValueAsNumber_number) {
                                    if (valueAsNumber_number !== minValueAsNumber_number) {
                                        errors.push({ rule: "value", message: <Trans>Must be {minValue} (exactly)</Trans> });

                                        if (errorMode === "single") return errors;
                                    }
                                } else {
                                    if (minMaxValueExclusive) {
                                        if (valueAsNumber_number <= minValueAsNumber_number || valueAsNumber_number >= maxValueAsNumber_number) {
                                            errors.push({
                                                rule: "value",
                                                message: (
                                                    <Trans>
                                                        Must be between {minValue} and {maxValue} (exclusive)
                                                    </Trans>
                                                ),
                                            });

                                            if (errorMode === "single") return errors;
                                        }
                                    } else {
                                        if (valueAsNumber_number < minValueAsNumber_number || valueAsNumber_number > maxValueAsNumber_number) {
                                            errors.push({
                                                rule: "value",
                                                message: (
                                                    <Trans>
                                                        Must be between {minValue} and {maxValue} (inclusive)
                                                    </Trans>
                                                ),
                                            });

                                            if (errorMode === "single") return errors;
                                        }
                                    }
                                }
                            } else {
                                if (minMaxValueExclusive) {
                                    if (minValueAsNumber_number != null && valueAsNumber_number <= minValueAsNumber_number) {
                                        errors.push({ rule: "value", message: <Trans>Must be greater than {minValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }

                                    if (maxValueAsNumber_number != null && valueAsNumber_number >= maxValueAsNumber_number) {
                                        errors.push({ rule: "value", message: <Trans>Must be less than {maxValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }
                                } else {
                                    if (minValueAsNumber_number != null && valueAsNumber_number < minValueAsNumber_number) {
                                        errors.push({ rule: "value", message: <Trans>Must be at least {minValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }

                                    if (maxValueAsNumber_number != null && valueAsNumber_number > maxValueAsNumber_number) {
                                        errors.push({ rule: "value", message: <Trans>Must be at most {maxValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }
                                }
                            }
                        }

                        break;
                    case "color":
                        if (required && value == null) {
                            errors.push({ rule: "required", message: <Trans>Required</Trans> });

                            if (errorMode === "single") return errors;
                        }

                        break;
                    case "radiobutton":
                        if (required && value == null) {
                            errors.push({ rule: "required", message: <Trans>Required</Trans> });

                            if (errorMode === "single") return errors;
                        }

                        break;
                    case "date":
                    case "time":
                    case "datetime":
                        if (required && value == null) {
                            errors.push({ rule: "required", message: <Trans>Required</Trans> });

                            if (errorMode === "single") return errors;
                        }

                        const minValueAsNumber_timestamp = minValue != null && minValue instanceof Date ? minValue : null;
                        const maxValueAsNumber_timestamp = maxValue != null && maxValue instanceof Date ? maxValue : null;
                        const valueAsNumber_timestamp = value != null && value instanceof Date ? value : null;

                        if (valueAsNumber_timestamp != null) {
                            if (minValueAsNumber_timestamp != null && maxValueAsNumber_timestamp != null) {
                                if (minValueAsNumber_timestamp === maxValueAsNumber_timestamp) {
                                    if (valueAsNumber_timestamp !== minValueAsNumber_timestamp) {
                                        errors.push({ rule: "value", message: <Trans>Must be {minValue} (exactly)</Trans> });

                                        if (errorMode === "single") return errors;
                                    }
                                } else {
                                    if (minMaxValueExclusive) {
                                        if (valueAsNumber_timestamp <= minValueAsNumber_timestamp || valueAsNumber_timestamp >= maxValueAsNumber_timestamp) {
                                            errors.push({
                                                rule: "value",
                                                message: (
                                                    <Trans>
                                                        Must be between {minValue} and {maxValue} (exclusive)
                                                    </Trans>
                                                ),
                                            });

                                            if (errorMode === "single") return errors;
                                        }
                                    } else {
                                        if (valueAsNumber_timestamp < minValueAsNumber_timestamp || valueAsNumber_timestamp > maxValueAsNumber_timestamp) {
                                            errors.push({
                                                rule: "value",
                                                message: (
                                                    <Trans>
                                                        Must be between {minValue} and {maxValue} (inclusive)
                                                    </Trans>
                                                ),
                                            });

                                            if (errorMode === "single") return errors;
                                        }
                                    }
                                }
                            } else {
                                if (minMaxValueExclusive) {
                                    if (minValueAsNumber_timestamp != null && valueAsNumber_timestamp <= minValueAsNumber_timestamp) {
                                        errors.push({ rule: "value", message: <Trans>Must be greater than {minValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }

                                    if (maxValueAsNumber_timestamp != null && valueAsNumber_timestamp >= maxValueAsNumber_timestamp) {
                                        errors.push({ rule: "value", message: <Trans>Must be less than {maxValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }
                                } else {
                                    if (minValueAsNumber_timestamp != null && valueAsNumber_timestamp < minValueAsNumber_timestamp) {
                                        errors.push({ rule: "value", message: <Trans>Must be at least {minValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }

                                    if (maxValueAsNumber_timestamp != null && valueAsNumber_timestamp > maxValueAsNumber_timestamp) {
                                        errors.push({ rule: "value", message: <Trans>Must be at most {maxValue}</Trans> });

                                        if (errorMode === "single") return errors;
                                    }
                                }
                            }
                        }

                        break;
                    default:
                    // Do nothing.
                }

                // Handle custom validation rule (if present).
                if (custom) {
                    const customError = custom(name, value);

                    if (customError != null) {
                        errors.push({ rule: "custom", message: customError });

                        if (errorMode === "single") return errors;
                    }
                }
            }

            return errors;
        },
        [errorMode]
    );

    const validateForm = useCallback(
        (fields: Field[]): FormErrors => {
            const errors: FormErrors = {};

            fields.forEach((field) => {
                errors[field.name] = validateField(field);
            });

            return errors;
        },
        [validateField]
    );

    const formValidator = useMemo((): FormValidator => {
        return {
            setErrorMode: (mode: "single" | "multiple") => {
                setErrorMode((prevState) => {
                    if (mode === prevState) {
                        return prevState;
                    } else {
                        return mode;
                    }
                });
            },

            registerField: (name: string, type: FieldType, initialValue: any, validationRules: ValidationRules) => {
                setFields((prevState) => {
                    const existingField = prevState.find((item) => item.name === name);

                    const newState = [...prevState];

                    if (existingField) {
                        const idx = newState.indexOf(existingField);

                        // Registering a field that is already registered just results in updating the validation rules associated with it.
                        // All other data remains unchanged.
                        newState[idx] = {
                            name: existingField.name,
                            type: existingField.type,
                            value: existingField.value,
                            validationRules: validationRules,
                        };

                        return newState;
                    } else {
                        newState.push({
                            name: name,
                            type: type,
                            value: initialValue,
                            validationRules: validationRules,
                        });
                    }

                    return newState;
                });

                setFormErrors((prevState) => {
                    if (!prevState[name]) {
                        const newState = Object.assign({}, formErrors) as FormErrors;

                        newState[name] = [];

                        return newState;
                    } else {
                        return prevState;
                    }
                });
            },

            unregisterField: (name: string) => {
                setFields((prevState) => {
                    const existingField = prevState.find((item) => item.name === name);

                    if (existingField) {
                        const newState = [...prevState];

                        const idx = newState.indexOf(existingField);

                        newState.splice(idx, 1);

                        return newState;
                    } else {
                        return prevState;
                    }
                });

                setFormErrors((prevState) => {
                    if (prevState[name]) {
                        const newState = Object.assign({}, formErrors) as FormErrors;

                        delete newState[name];

                        return newState;
                    } else {
                        return prevState;
                    }
                });
            },

            reset: () => {
                setPreviouslyValidated(false);

                setIsValid(true);

                setFormErrors({});
            },

            validate: () => {
                const formErrors = validateForm(fields);

                const formKeys = Object.keys(formErrors);

                let valid = true;

                for (let x = 0, n = formKeys.length; x < n; ++x) {
                    const formKey = formKeys[x];
                    const fieldErrors = formErrors[formKey];

                    if (fieldErrors && fieldErrors.length > 0) {
                        valid = false;

                        break;
                    }
                }

                if (!previouslyValidated) {
                    setPreviouslyValidated(true);
                }

                setIsValid(valid);

                setFormErrors(formErrors);

                return valid;
            },

            fieldFocused: (name: string) => {
                setFormErrors((prevState) => {
                    const prevFieldErrors = prevState[name];

                    if (prevFieldErrors && prevFieldErrors.length > 0) {
                        const newState = Object.assign({}, prevState) as FormErrors;

                        newState[name] = [];

                        return newState;
                    } else {
                        return prevState;
                    }
                });
            },

            fieldBlurred: (name: string) => {
                if (previouslyValidated) {
                    setFormErrors((prevState) => {
                        const field = fields.find((item) => item.name === name);

                        if (field) {
                            const newState = Object.assign({}, prevState) as FormErrors;

                            newState[name] = validateField(field);

                            return newState;
                        } else {
                            return prevState;
                        }
                    });
                }
            },

            fieldUpdated: (name: string, value: any) => {
                setFields((prevState) => {
                    const field = prevState.find((item) => item.name === name);

                    if (field) {
                        const newState = [...prevState];

                        const idx = newState.indexOf(field);

                        newState[idx] = {
                            name: field.name,
                            type: field.type,
                            value: value,
                            validationRules: field.validationRules,
                        };

                        return newState;
                    } else {
                        return prevState;
                    }
                });

                setFormErrors((prevState) => {
                    const prevFieldErrors = prevState[name];

                    if (prevFieldErrors && prevFieldErrors.length > 0) {
                        const newState = Object.assign({}, prevState) as FormErrors;

                        newState[name] = [];

                        return newState;
                    } else {
                        return prevState;
                    }
                });
            },

            getErrors: (name: string) => {
                const fieldErrors = formErrors[name];

                return fieldErrors || [];
            },
        };
    }, [formErrors, fields, previouslyValidated, validateField, validateForm]);

    return [formValidator, previouslyValidated, isValid, formErrors];
};

/** HoC implementation. */
export interface withFormValidatorProps {
    bcFormValidator: FormValidator;
    bcFormPreviouslyValidated: boolean;
    bcFormIsValid: boolean;
    bcFormErrors: FormErrors;
}

export const withFormValidator = (initialErrorMode?: "single" | "multiple") => {
    return <P extends withFormValidatorProps>(Component: React.ComponentType<P>): React.ComponentType<Pick<P, Exclude<keyof P, keyof withFormValidatorProps>>> => {
        return (props: Pick<P, Exclude<keyof P, keyof withFormValidatorProps>>) => {
            const [formValidator, previouslyValidated, isValid, formErrors] = useFormValidator(initialErrorMode);

            return <Component {...(props as P)} bcFormValidator={formValidator} bcFormPreviouslyValidated={previouslyValidated} bcFormIsValid={isValid} bcFormErrors={formErrors} />;
        };
    };
};
