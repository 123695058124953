// Import libraries.
import React from "react";

// Import types.
import { ColumnDefinition, FilterData, FilterOverrides, RowData } from "./types";
import { FieldOptions, FieldType } from "../form/FieldWrapper";

// Import components.
import { Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import FieldWrapper from "components/common/form/FieldWrapper";
import Timestamp from "components/common/Timestamp";

// Import formatters.
import BooleanFormatter from "utils/formatters/Booleanformatter";
import NumberFormatter from "utils/formatters/Number";

// Define the default set of page sizes.
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = 50;

// Define the default row height.
export const DEFAULT_ROW_HEIGHT = 32;

// Define the default bottom border size (applied by Material-UI's 'TableCell-root').
export const DEFAULT_BOTTOM_BORDER_SIZE = 1;

// Define the default max details height.
export const DEFAULT_DETAILS_ROW_MAX_HEIGHT = 300;

// Define the default overflow count (when virutalized).
export const DEFAULT_OVERFLOW_COUNT = 10;

// Default text to render when no value is present for a particular cell.
export const NO_VALUE_FOR_CELL = "-";

const computeEffectiveRowHeight = (index: number, heights: number[], detailsEnabled: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let effectiveRowHeight = 0;

    const cachedHeight = heights[index] || 0;

    if (cachedHeight === 0) {
        if (detailsEnabled) {
            if (index % 2 === 0) {
                effectiveRowHeight += rowHeight != null ? rowHeight : DEFAULT_ROW_HEIGHT;
            }
        } else {
            effectiveRowHeight += rowHeight != null ? rowHeight : DEFAULT_ROW_HEIGHT;
        }
    } else {
        effectiveRowHeight += cachedHeight;
    }

    if (detailsEnabled) {
        if (index % 2 !== 0) {
            effectiveRowHeight += bottomBorderSize != null ? bottomBorderSize : DEFAULT_BOTTOM_BORDER_SIZE;
        }
    }

    return effectiveRowHeight;
};

export const determineFirstVisibleRow = (scrollTop: number, rowCount: number, heights: number[], detailsEnabled: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let index = 0;

    let height = 0;

    for (let x = 0, n = rowCount; x < n; ++x) {
        height += computeEffectiveRowHeight(x, heights, detailsEnabled, rowHeight, bottomBorderSize);

        if (height > scrollTop) {
            index = x;

            break;
        }
    }

    return index;
};

export const determineLastVisibleRow = (scrollBottom: number, rowCount: number, heights: number[], detailsEnabled: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let index = 0;

    let height = 0;

    for (let x = 0, n = rowCount; x < n; ++x) {
        if (height >= scrollBottom) {
            break;
        } else {
            height += computeEffectiveRowHeight(x, heights, detailsEnabled, rowHeight, bottomBorderSize);

            index = x;
        }
    }

    return index;
};

export const determineVisibleHeight = (firstVisibleRowIdx: number, lastVisibleRowIdx: number, heights: number[], detailsEnabled: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let height = 0;

    for (let x = firstVisibleRowIdx, n = lastVisibleRowIdx; x <= n; ++x) {
        height += computeEffectiveRowHeight(x, heights, detailsEnabled, rowHeight, bottomBorderSize);
    }

    return height;
};

export const determineTopOverflowHeight = (firstVisibleRowIdx: number, topOverflowRowCount: number, heights: number[], detailsEnabled: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let height = 0;

    for (let x = 0, n = topOverflowRowCount; x < n; ++x) {
        height += computeEffectiveRowHeight(firstVisibleRowIdx - (x + 1), heights, detailsEnabled, rowHeight, bottomBorderSize);
    }

    return height;
};

export const determineBottomOverflowHeight = (lastVisibleRowIdx: number, bottomOverflowRowCount: number, heights: number[], detailsEnabled: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let height = 0;

    for (let x = 0, n = bottomOverflowRowCount; x < n; ++x) {
        height += computeEffectiveRowHeight(lastVisibleRowIdx + (x + 1), heights, detailsEnabled, rowHeight, bottomBorderSize);
    }

    return height;
};

export const determineTopBufferHeight = (firstVisibleRowIdx: number, topOverflowRowCount: number, heights: number[], detailsEnabled: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let height = 0;

    for (let x = 0, n = firstVisibleRowIdx; x < n; ++x) {
        height += computeEffectiveRowHeight(x, heights, detailsEnabled, rowHeight, bottomBorderSize);
    }

    return height - determineTopOverflowHeight(firstVisibleRowIdx, topOverflowRowCount, heights, detailsEnabled, rowHeight, bottomBorderSize);
};

export const determineBottomBufferHeight = (
    firstVisibleRowIdx: number,
    lastVisibleRowIdx: number,
    topOverflowRowCount: number,
    bottomOverflowRowCount: number,
    pageHeight: number,
    heights: number[],
    detailsEnabled: boolean,
    rowHeight?: number,
    bottomBorderSize?: number
) => {
    const topBufferHeight = determineTopBufferHeight(firstVisibleRowIdx, topOverflowRowCount, heights, detailsEnabled, rowHeight, bottomBorderSize);
    const topOverflowHeight = determineTopOverflowHeight(firstVisibleRowIdx, topOverflowRowCount, heights, detailsEnabled, rowHeight, bottomBorderSize);
    const visibleHeight = determineVisibleHeight(firstVisibleRowIdx, lastVisibleRowIdx, heights, detailsEnabled, rowHeight, bottomBorderSize);
    const bottomOverflowHeight = determineBottomOverflowHeight(lastVisibleRowIdx, bottomOverflowRowCount, heights, detailsEnabled, rowHeight, bottomBorderSize);

    return pageHeight - topBufferHeight - topOverflowHeight - visibleHeight - bottomOverflowHeight;
};

export const determinePageHeight = (numberOfRows: number, rowHeights: number[], detailsEnabled: boolean, detailsExpanded: boolean, rowHeight?: number, bottomBorderSize?: number) => {
    let height = 0;

    const numberOfCachedHeights = rowHeights.length;

    const applicableCachedRowHeights = rowHeights.slice(0, Math.min(numberOfCachedHeights, numberOfRows));

    const numberOfApplicableCachedHeights = applicableCachedRowHeights.length;

    if (numberOfApplicableCachedHeights > 0) {
        // First we go through the cached row heights (these represent the currently "known" row heights, which may or may not cover all necessary rows).
        for (let x = 0, n = numberOfApplicableCachedHeights; x < n; ++x) {
            if (detailsEnabled && x % 2 !== 0 && !detailsExpanded) {
                height += DEFAULT_BOTTOM_BORDER_SIZE;
            } else {
                height += computeEffectiveRowHeight(x, applicableCachedRowHeights, detailsEnabled);
            }
        }

        // Determine the lowest "known" row height (excluding the "details" rows and any cached heights that are currently 0).
        const validLowestCachedRowHeights = applicableCachedRowHeights.filter((value, idx) => value > 0 && (detailsEnabled ? idx % 2 === 0 : true));
        const lowestCachedRowHeight = validLowestCachedRowHeights.length > 0 ? Math.min(...validLowestCachedRowHeights) : rowHeight;

        // Finally, fill out any rows that may have been missed above (meaning we have no "known" cached heights for them).
        for (let x = numberOfCachedHeights, n = numberOfRows; x < n; ++x) {
            if (detailsEnabled && x % 2 !== 0 && !detailsExpanded) {
                height += bottomBorderSize != null ? bottomBorderSize : DEFAULT_BOTTOM_BORDER_SIZE;
            } else {
                height += computeEffectiveRowHeight(x, [], detailsEnabled, lowestCachedRowHeight);
            }
        }
    }

    return height;
};

/**
 * A default comparator function used for sorting columns.
 *
 * Supports string, number and boolean value comparison.
 *
 * Any other value type is stringified (using JSON.stringify) and compared as a string.
 *
 * A null value is always considered "less than" a non-null value (based on ascending order).
 *
 * @param orderBy
 * @param orderDirection
 */
export const defaultCellComparator = (orderBy: string, orderDirection: "asc" | "desc") => {
    return (a: RowData, b: RowData): number => {
        if (a == null || b == null) {
            if (a == null && b == null) return 0;
            else {
                if (a == null) return 1;
                else return -1;
            }
        } else if (a[orderBy] == null || b[orderBy] == null) {
            if (a[orderBy] == null && b[orderBy] == null) return 0;
            else {
                if (a[orderBy] == null) return 1;
                else return -1;
            }
        } else if (typeof a[orderBy] === "boolean" && typeof b[orderBy] === "boolean") {
            const aValue = a[orderBy] as boolean;
            const bValue = b[orderBy] as boolean;

            if (aValue === bValue) return 0;
            if (!aValue && bValue) return orderDirection === "asc" ? -1 : 1;
            if (aValue && !bValue) return orderDirection === "asc" ? 1 : -1;

            return 0;
        } else if (typeof a[orderBy] === "number" && typeof b[orderBy] === "number") {
            const aValue = a[orderBy] as number;
            const bValue = b[orderBy] as number;

            if (aValue === bValue) return 0;
            if (aValue < bValue) return orderDirection === "asc" ? -1 : 1;
            if (aValue > bValue) return orderDirection === "asc" ? 1 : -1;

            return 0;
        } else if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
            const aValue = a[orderBy] as string;
            const bValue = b[orderBy] as string;

            return orderDirection === "asc" ? aValue.localeCompare(bValue) : -aValue.localeCompare(bValue);
        } else {
            const aValue = JSON.stringify(a[orderBy]);
            const bValue = JSON.stringify(b[orderBy]);

            return orderDirection === "asc" ? aValue.localeCompare(bValue) : -aValue.localeCompare(bValue);
        }
    };
};

/**
 * A default filter function used for filtering rows.
 *
 * Optionally accepts a map of filter overrides to allow the parent
 * component to customize the filtering behaviour.
 *
 * All applicable filters must be satisfied.
 *
 * @param filterData
 * @param filterOverrides
 */
export const defaultRowFilter = (filterData?: FilterData | null, filterOverrides?: FilterOverrides) => {
    return (row: RowData, _idx: number): boolean => {
        if (row != null && filterData != null) {
            const filterKeys = Object.keys(filterData);

            let matchesAllFilters = true;

            for (let x = 0, n = filterKeys.length; x < n; ++x) {
                const filterKey = filterKeys[x];
                const filterOverride = filterOverrides != null && filterOverrides[filterKey] != null ? filterOverrides[filterKey] : null;

                if (filterOverride != null) {
                    if (filterOverride(row, filterData[filterKey]) === false) {
                        matchesAllFilters = false;
                    }
                } else {
                    const filterValue = filterData[filterKey] != null ? "" + filterData[filterKey] : null;

                    if (filterValue != null) {
                        const colValue = row[filterKey] != null ? "" + row[filterKey] : null;

                        if (colValue == null || !colValue.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
                            matchesAllFilters = false;
                        }
                    }
                }
            }

            return matchesAllFilters;
        } else {
            return true;
        }
    };
};

/**
 * Generates a basic column definition for a simple value type.
 *
 * @param id
 * @param label
 * @param options
 * @returns
 */
export const createBasicColumn = (
    id: string,
    label: React.ReactNode,
    options?: {
        sortable?: boolean;
        initialSortDirection?: "asc" | "desc";
        align?: "left" | "right" | "center" | "inherit";
        width?: string | number;
        tooltip?: boolean;
        tooltipText?: React.ReactNode | ((row: RowData) => React.ReactNode);
        valueType?: "string" | "integer" | "float" | "boolean" | "currency" | "date" | "json";
        bypassFormatter?: boolean;
        resizable?: boolean;
        decimalScale?: number;
        fixedDecimalScale?: boolean;
    }
): ColumnDefinition => {
    return {
        id: id,
        label: label,
        sortable: options ? options.sortable : undefined,
        initialSortDirection: options ? options.initialSortDirection : undefined,
        resizable: options && options.width ? options.resizable : undefined,
        align: options ? options.align : undefined,
        width: options ? options.width : undefined,
        format: (value: any, row: RowData) => {
            let renderedValue: React.ReactNode = null;

            let valueType = options && options.valueType ? options.valueType : value instanceof Date ? "date" : typeof value;

            if (valueType === "number") {
                if (Number.isInteger(value)) {
                    valueType = "integer";
                } else {
                    valueType = "float";
                }
            }

            switch (valueType) {
                case "string":
                    renderedValue = value;
                    break;
                case "integer":
                    renderedValue = options?.bypassFormatter ? value : !Number.isNaN(value) ? NumberFormatter.formatInteger(value) : "" + value;
                    break;
                case "float":
                    renderedValue = options?.bypassFormatter ? value : !Number.isNaN(value) ? NumberFormatter.formatFloat(value, options?.decimalScale, options?.fixedDecimalScale) : "" + value;
                    break;
                case "boolean":
                    renderedValue = options?.bypassFormatter ? value : BooleanFormatter.formatBoolean(value, true);
                    break;
                case "currency":
                    renderedValue = options?.bypassFormatter ? value : NumberFormatter.formatCurrency(value / 100, undefined, options?.decimalScale, options?.fixedDecimalScale);
                    break;
                case "date":
                    renderedValue = options?.bypassFormatter ? value : <Timestamp value={value} renderAsString={true} />;
                    break;
                case "json":
                    renderedValue = options?.bypassFormatter ? value : JSON.stringify(value);
                    break;
                default:
                    renderedValue = value;
            }

            if (renderedValue == null) renderedValue = NO_VALUE_FOR_CELL;

            if (options && options.tooltip) {
                const tooltipText = options && options.tooltipText ? options.tooltipText : renderedValue;

                return (
                    <Tooltip arrow title={typeof tooltipText === "function" ? tooltipText(row) : <Typography>{tooltipText}</Typography>}>
                        <Typography noWrap>{renderedValue}</Typography>
                    </Tooltip>
                );
            } else {
                return <Typography noWrap>{renderedValue}</Typography>;
            }
        },
    };
};

/**
 * Generates a input column definition using the FieldWrapper and supported field type.
 *
 * @param id
 * @param label
 * @param options
 * @returns
 */
export const createInputFieldColumn = (
    id: string,
    label: React.ReactNode,
    options?: {
        className?: string | ((value: any, row: RowData) => string);
        sortable?: boolean;
        initialSortDirection?: "asc" | "desc";
        align?: "left" | "right" | "center" | "inherit";
        width?: string | number;
        fieldType?: FieldType;
        fieldOptions?: FieldOptions;
        required?: boolean;
        readonly?: boolean;
        disabled?: boolean;
        resizable?: boolean;
        onClick?: (row: RowData) => void;
        onChange?: (value: any, row: RowData) => void;
        getValue?: (row: RowData) => any;
        getFieldOptions?: (row: RowData) => FieldOptions;
    }
): ColumnDefinition => {
    return {
        id: id,
        label: label,
        sortable: options ? options.sortable : undefined,
        initialSortDirection: options ? options.initialSortDirection : undefined,
        resizable: options && options.width ? options.resizable : undefined,
        align: options ? options.align : undefined,
        width: options ? options.width : undefined,
        format: (value: any, row: RowData, onChange?: (columnId: string, newValue: any, row: RowData) => void) => {
            const className = options && options.className ? (typeof options.className === "function" ? options.className(value, row) : options.className) : undefined;

            const effectiveFieldOptions = {} as FieldOptions;

            Object.assign(effectiveFieldOptions, options?.fieldOptions || {});
            if (options?.getFieldOptions) {
                Object.assign(effectiveFieldOptions, options.getFieldOptions(row));
            }

            return (
                <FieldWrapper
                    className={className}
                    style={{ width: "100%", margin: 0 }}
                    type={options?.fieldType}
                    name={id}
                    value={options?.getValue ? options.getValue(row) : value}
                    readonly={options?.readonly}
                    disabled={options?.disabled}
                    required={options?.required}
                    options={effectiveFieldOptions}
                    onChange={(_name: string, newValue: any) => {
                        if (onChange) {
                            onChange(id, newValue, row);

                            if (options && options.onChange) {
                                options.onChange(newValue, row);
                            }
                        }
                    }}
                    onClick={(_name: string) => {
                        if (options && options.onClick) {
                            options.onClick(row);
                        }
                    }}
                />
            );
        },
    };
};

/**
 * Generates a custom column definition allowing the you to fully customize the rendering behaviour of the cell content.
 *
 * @param id
 * @param label
 * @param options
 * @returns
 */
export const createCustomColumn = (
    id: string,
    label: React.ReactNode,
    options?: {
        sortable?: boolean;
        initialSortDirection?: "asc" | "desc";
        align?: "left" | "right" | "center" | "inherit";
        width?: string | number;
        resizable?: boolean;
        format?: (row: RowData, onChange?: (columnId: string, newValue: any, row: RowData) => void) => React.ReactNode;
    }
): ColumnDefinition => {
    return {
        id: id,
        label: label,
        sortable: options ? options.sortable : undefined,
        initialSortDirection: options ? options.initialSortDirection : undefined,
        resizable: options && options.width ? options.resizable : undefined,
        align: options ? options.align : undefined,
        width: options ? options.width : undefined,
        format: (_value: any, row: RowData, onChange?: (columnId: string, newValue: any, row: RowData) => void) => {
            if (options && options.format) {
                return options.format(row, onChange);
            }
        },
    };
};
