// Import libraries.
import Http, { HttpResponse } from "utils/networking/Http";

// Import types.
import { PlayerPreview, processPlayerPreview, processUserPreview, UserPreview } from "types/models/PlayerSummaryInfo";
import { FlaggedData, processFlaggedData } from "components/screens/users/UserSummary/types";

const getPlayerPreview = async (playerId: string, abortSignal?: AbortSignal): Promise<PlayerPreview | null> => {
    const response: HttpResponse = await Http.GET("admin/monitoring/userPreview", { profileId: playerId }, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data != null && Object.keys(response.data).length > 0 ? processPlayerPreview(response.data) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};
const getPlayerFlaggedData = async (playerId: string, abortSignal?: AbortSignal): Promise<FlaggedData | null> => {
    const response: HttpResponse = await Http.GET("admin/monitoring/getflaggedplayer", { playerId: playerId }, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        if (response.data && Object.keys(response.data).length > 0) {
            return Promise.resolve(processFlaggedData(response.data));
        } else {
            return Promise.resolve(null);
        }
    } else {
        return Promise.reject(Http.buildError(response));
    }
};
const getUserPreviewConfig = async (abortSignal?: AbortSignal): Promise<UserPreview> => {
    const response: HttpResponse = await Http.GET("/admin/development/portal/feature", { featureName: "userPreview" }, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processUserPreview(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    getPlayerPreview,
    getPlayerFlaggedData,
    getUserPreviewConfig,
};

export default Services;
