import { Segment } from "components/screens/design/Segmentation/Segments/types";

export default interface PlayerSummary {
    playerId: string;

    isTester: boolean;

    abTestingId: number | null;

    name: string | null;
    email: string | null;

    pictureUrl: string | null;

    timezoneOffset: number | null;
    languageCode: string | null;
    countryCode: string | null;

    credentials: PlayerCredential[];

    platforms: string[];

    deviceTokens: DeviceToken[];

    vcPurchased: number | null;
    vcClaimed: number | null;

    purchaseCount: number | null;
    refundCount: number | null;
    amountSpent: number | null;

    loginCount: number | null;
    timeSpent: number | null;

    previousLogin: Date | null;
    lastLogin: Date | null;

    createdAt: Date | null;
    updatedAt: Date | null;

    summaryData: any;

    failedRedemptionsTotal: number;
    totalBadRedemptionAttemptsAllowed: number;

    badLoginAttempts: number;
    blocked: boolean;
    blockedUntil: Date | null;
}

export interface PlayerPreview {
    amountSpent: number | null;
    countryCode: string | null;
    createdAt: Date | null;
    emailAddress: string | null;
    lastLogin: Date | null;
    loginCount: number | null;
    logoutCount: number | null;
    notes: string;
    playerId: string;
    playerName: string | null;
    platforms: string[];
    profileId: string;
    purchases30Days: number | null;
    purchases30DaysPending: number | null;
    segments: Segment[];
    summaryData: any;
    timeSpent: number | null;
    badLoginAttempts: number;
    blocked: boolean;
    blockedUntil: Date | null;
}
export const processPlayerPreview = (data: any): PlayerPreview => {
    return {
        amountSpent: data?.amountSpent != null ? Number.parseInt(data.amountSpent) : null,
        countryCode: data?.countryCode || null,
        createdAt: data?.createdAt != null ? new Date(data.createdAt) : null,
        emailAddress: data?.email || data?.emailAddress || null,
        lastLogin: data?.lastLogin != null ? new Date(data.lastLogin) : null,
        loginCount: data?.loginCount != null ? Number.parseInt(data.loginCount) : null,
        logoutCount: data?.logouts != null ? Number.parseInt(data.logouts) : null,
        notes: data?.notes || "",
        playerId: data?.playerId || "",
        playerName: data?.name || data?.playerName || null,
        platforms: Array.isArray(data?.platforms) ? (data.platforms as string[]) : [],
        profileId: data?.profileId || "",
        purchases30Days: data?.purchases30Days != null ? Number.parseInt(data.purchases30Days) : null,
        purchases30DaysPending: data?.purchases30DaysPending !== null ? Number.parseInt(data.purchases30DaysPending) : null,
        segments:
            data?.segments?.map((item: any) => {
                return {
                    segmentId: item.segmentId,
                    name: item.name,
                };
            }) || [],
        summaryData: data?.summaryFriendData != null ? (typeof data.summaryFriendData === "object" ? JSON.stringify(data.summaryFriendData, undefined, 2) : data.summaryFriendData) : null,
        timeSpent: data?.allTimeSecs != null ? Number.parseInt(data.allTimeSecs) : null,
        badLoginAttempts: data?.badLoginAttempts ? Number.parseInt(data.badLoginAttempts) : 0,
        blocked: data?.blocked === true,
        blockedUntil: data?.blockedUntilMillis != null ? new Date(data.blockedUntilMillis) : null,
    };
};

export interface UserPreview {
    showRevenueInfo: boolean;
}
export const processUserPreview = (data: any): UserPreview => {
    return {
        showRevenueInfo: data?.showRevenueInfo ?? true,
    };
};

export const processPlayerSummary = (data: any): PlayerSummary => {
    return {
        playerId: data?.playerId || "",

        isTester: data?.isTester === true,

        abTestingId: data?.ab_testing_id != null ? Number.parseInt(data.ab_testing_id) : null,

        name: data?.name || data?.playerName || null,
        email: data?.email || data?.emailAddress || null,

        pictureUrl: data?.pictureUrl || null,

        timezoneOffset: data?.timeZoneOffset != null ? Number.parseFloat(data.timeZoneOffset) : null,
        languageCode: data?.languageCode || null,
        countryCode: data?.countryCode || null,

        credentials: Array.isArray(data?.credentials) ? data.credentials.map(processPlayerCredential) : [],

        platforms: Array.isArray(data?.platforms) ? (data.platforms as string[]) : [],

        deviceTokens: Array.isArray(data?.deviceTokens) ? data.deviceTokens.map(processDeviceToken) : [],

        vcPurchased: data?.vcPurchased != null ? Number.parseInt(data.vcPurchased) : null,
        vcClaimed: data?.vcClaimed != null ? Number.parseInt(data.vcClaimed) : null,

        purchaseCount: data?.purchaseCount != null ? Number.parseInt(data.purchaseCount) : null,
        refundCount: data?.refundCount != null ? Number.parseInt(data.refundCount) : null,
        amountSpent: data?.amountSpent != null ? Number.parseInt(data.amountSpent) : null,

        loginCount: data?.loginCount != null ? Number.parseInt(data.loginCount) : null,
        timeSpent: data?.allTimeSecs != null ? Number.parseInt(data.allTimeSecs) : null,

        previousLogin: data?.previousLogin != null ? new Date(data.previousLogin) : null,
        lastLogin: data?.lastLogin != null ? new Date(data.lastLogin) : null,

        createdAt: data?.createdAt != null ? new Date(data.createdAt) : null,
        updatedAt: data?.updatedAt != null ? new Date(data.updatedAt) : null,

        summaryData: data?.summaryFriendData != null ? (typeof data.summaryFriendData === "object" ? JSON.stringify(data.summaryFriendData, undefined, 2) : data.summaryFriendData) : null,

        failedRedemptionsTotal: data?.failedRedemptionsTotal != null ? Number.parseInt(data.failedRedemptionsTotal) : 0,
        totalBadRedemptionAttemptsAllowed: data?.totalBadRedemptionAttemptsAllowed != null ? Number.parseInt(data.totalBadRedemptionAttemptsAllowed) : 0,

        badLoginAttempts: data?.badLoginAttempts ? Number.parseInt(data.badLoginAttempts) : 0,
        blocked: data?.blocked === true,
        blockedUntil: data?.blockedUntilMillis != null ? new Date(data.blockedUntilMillis) : null,
    };
};

export interface PlayerCredential {
    authenticationType: string;
    authSubType: string | null;
    deviceInfoList: {
        deviceType: string;
        deviceId: string;
    }[];
    externalId: string;
}

export const processPlayerCredential = (data: any): PlayerCredential => {
    return {
        authenticationType: data.authenticationType || "",
        authSubType: data.authSubType || null,
        deviceInfoList: Array.isArray(data.deviceInfo)
            ? (data.deviceInfo as any[]).map((item) => {
                  return {
                      deviceType: item.deviceType || "",
                      deviceId: item.deviceId || "",
                  };
              })
            : [],
        externalId: data.externalId,
    };
};

export interface DeviceToken {
    deviceType: string;
    deviceToken: string;
    lastRegisteredAt: Date;
}

export const processDeviceToken = (data: any): DeviceToken => {
    return {
        deviceType: data.deviceType || "",
        deviceToken: data.deviceToken || "",
        lastRegisteredAt: new Date(data.lastRegisteredAt),
    };
};
