// Import types.
import PortalBranding from "types/common/PortalBranding";
import Theme from "types/common/Theme";

interface ColorDef {
    type: "portal" | "chart-dataset";
    key: string | number;
    value: string;
}

/**
 * Branding utility functions.
 */
export default abstract class BrandingUtils {
    private static PORTAL_COLORS_ARRAY = Array.from<string, ColorDef>(["green", "red", "yellow", "orange", "blue", "lightBlue", "aqua", "olive", "pink", "magenta", "purple", "brown", "grey"], (x, _i) => ({
        type: "portal",
        key: x.toLowerCase(),
        value: `portal-color-${x}`,
    }));

    public static PORTAL_COLORS = {
        GREEN: BrandingUtils.PORTAL_COLORS_ARRAY[0],
        RED: BrandingUtils.PORTAL_COLORS_ARRAY[1],
        YELLOW: BrandingUtils.PORTAL_COLORS_ARRAY[2],
        ORANGE: BrandingUtils.PORTAL_COLORS_ARRAY[3],
        BLUE: BrandingUtils.PORTAL_COLORS_ARRAY[4],
        LIGHT_BLUE: BrandingUtils.PORTAL_COLORS_ARRAY[5],
        AQUA: BrandingUtils.PORTAL_COLORS_ARRAY[6],
        OLIVE: BrandingUtils.PORTAL_COLORS_ARRAY[7],
        PINK: BrandingUtils.PORTAL_COLORS_ARRAY[8],
        MAGENTA: BrandingUtils.PORTAL_COLORS_ARRAY[9],
        PURPLE: BrandingUtils.PORTAL_COLORS_ARRAY[10],
        BROWN: BrandingUtils.PORTAL_COLORS_ARRAY[11],
        GREY: BrandingUtils.PORTAL_COLORS_ARRAY[12],
    };

    public static CHART_DATASET_COLORS = Array.from<any, ColorDef>({ length: 21 }, (_x, i) => ({ type: "chart-dataset", key: i, value: `chart-dataset-color-${i}` }));

    public static getColorIndex(datasetIndex: number, skipCount: number = 0, colors: ColorDef[] = BrandingUtils.CHART_DATASET_COLORS) {
        if (datasetIndex < skipCount) {
            return datasetIndex;
        } else {
            return skipCount + ((datasetIndex - skipCount) % (colors.length - skipCount));
        }
    }

    public static getColorVar(datasetIndex: number, skipCount: number = 0, colors: ColorDef[] = BrandingUtils.CHART_DATASET_COLORS) {
        return colors[BrandingUtils.getColorIndex(datasetIndex, skipCount, colors)].value || undefined;
    }

    public static getCSSValue(activeTheme?: Theme | null, themeMode?: "light" | "dark" | null, name?: string | null): string | undefined {
        if (activeTheme && themeMode && activeTheme[themeMode] && name) {
            return activeTheme[themeMode].cssVariables[name] || undefined;
        } else {
            return undefined;
        }
    }

    /**
     * Convert a PortalBranding instance into injectable CSS content.
     *
     * @param name
     */
    public static generatePortalBrandingCSSContent(data?: PortalBranding | null): string | null {
        if (!data) return null;

        /* Support for customized scroll bars */
        let result = "*::-webkit-scrollbar {\n";
        if (data["scrollbar-size"]) result = result + "width: " + data["scrollbar-size"] + ";\n";
        if (data["scrollbar-size"]) result = result + "height: " + data["scrollbar-size"] + ";\n";
        result = result + "}\n";

        result = result + "*::-webkit-scrollbar-track {\n";
        if (data["scrollbar-track-color"]) result = result + "background-color: " + data["scrollbar-track-color"] + ";\n";
        if (data["scrollbar-track-color"]) result = result + "-box-shadow: " + data["scrollbar-track-color"] + ";\n";
        if (data["scrollbar-track-color"]) result = result + "-webkit-box-shadow: " + data["scrollbar-track-color"] + ";\n";
        result = result + "}\n";

        result = result + "*::-webkit-scrollbar-thumb {\n";
        if (data["scrollbar-thumb-color"]) result = result + "background-color: " + data["scrollbar-thumb-color"] + ";\n";
        result = result + "outline: 1px solid transparent;\n";
        if (data["scrollbar-thumb-radius"]) result = result + "border-radius: " + data["scrollbar-thumb-radius"] + ";\n";
        result = result + "}\n";

        /* Support for all other CSS variables */
        result = result + "\n:root {\n";
        Object.keys(data).forEach((key) => {
            // We skip ANY field that starts with and ends with "__" as these repreesent other types of branding data (like logo urls or the custom moncao theme).
            if (!(key.startsWith("__") && key.endsWith("__"))) {
                const value = data[key];

                if (value) {
                    result = result + "--" + key + ": " + value + ";\n";
                }
            }
        });
        result = result + "}\n";

        // Return the resulting CSS content.
        return result;
    }
}
