import { ReactNode } from "react";

import NumberFormat from "react-number-format";

export default abstract class NumberFormatter {
    public static formatInteger(value?: number | null): ReactNode {
        return value != null ? <NumberFormat value={value} displayType={"text"} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} /> : null;
    }
    public static formatFloat(value?: number | null, decimalScale?: number | null, fixedDecimalScale?: boolean | null): ReactNode {
        return value != null ? (
            <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={decimalScale != null ? decimalScale : decimalScale === null ? undefined : 3}
                fixedDecimalScale={fixedDecimalScale != null ? fixedDecimalScale : fixedDecimalScale === null ? undefined : true}
            />
        ) : null;
    }
    public static formatCurrency(value?: number | null, currencySymbol?: string | null, decimalScale?: number | null, fixedDecimalScale?: boolean | null): ReactNode {
        return value != null ? (
            <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currencySymbol != null ? currencySymbol : currencySymbol === null ? undefined : "$"}
                decimalScale={decimalScale != null ? decimalScale : decimalScale === null ? undefined : 2}
                fixedDecimalScale={fixedDecimalScale != null ? fixedDecimalScale : fixedDecimalScale === null ? undefined : true}
            />
        ) : null;
    }
}
