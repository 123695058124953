export enum PlatformIdDisplayName {
    AMAZON = "Amazon",
    ANG = "Google Android",
    APPLE_TV_OS = "Apple tvOS",
    APP_WORLD = "App World",
    BB = "BlackBerry",
    FB = "Facebook",
    GOOGLE_PLAY = "Google Play",
    IOS = "iOS",
    I_TUNES = "iTunes",
    LINUX = "Linux",
    MAC = "Mac OS X",
    PS3 = "Playstation 3",
    PS4 = "Playstation",
    PS_VITA = "Playstation Vita",
    ROKU = "Roku",
    STEAM = "Steam",
    TIZEN = "Tizen",
    UNKNOWN = "Unknown",
    WATCH_OS = "Apple watchOS",
    WEB = "Web",
    WII = "Wii",
    WINDOWS = "Windows",
    WINP = "Windows Phone",
    WINP8 = "Windows Phone 8",
    XBOX_360 = "Xbox 360",
    XBOX_ONE = "Xbox",
    MOCK = "Mock",
    NINTENDO = "Nintendo",
    OCULUS = "Oculus",
}

export const parsePlatformId = (value: any) => {
    switch (value) {
        case "AMAZON":
        case "amazon":
            return PlatformIdDisplayName.AMAZON;
        case "ANG":
            return PlatformIdDisplayName.ANG;
        case "APPLE_TV_OS":
            return PlatformIdDisplayName.APPLE_TV_OS;
        case "appworld":
            return PlatformIdDisplayName.APP_WORLD;
        case "BB":
        case "bb":
            return PlatformIdDisplayName.BB;
        case "FB":
        case "facebook":
            return PlatformIdDisplayName.FB;
        case "googlePlay":
            return PlatformIdDisplayName.GOOGLE_PLAY;
        case "IOS":
            return PlatformIdDisplayName.IOS;
        case "itunes":
            return PlatformIdDisplayName.I_TUNES;
        case "LINUX":
            return PlatformIdDisplayName.LINUX;
        case "MAC":
            return PlatformIdDisplayName.MAC;
        case "PS3":
            return PlatformIdDisplayName.PS3;
        case "PS4":
            return PlatformIdDisplayName.PS4;
        case "PS_VITA":
            return PlatformIdDisplayName.PS_VITA;
        case "ROKU":
            return PlatformIdDisplayName.ROKU;
        case "steam":
            return PlatformIdDisplayName.STEAM;
        case "WATCH_OS":
            return PlatformIdDisplayName.WATCH_OS;
        case "WEB":
            return PlatformIdDisplayName.WEB;
        case "WII":
            return PlatformIdDisplayName.WII;
        case "WIN":
        case "WINDOWS":
            return PlatformIdDisplayName.WINDOWS;
        case "WINP":
            return PlatformIdDisplayName.WINP;
        case "WINP8":
            return PlatformIdDisplayName.WINP8;
        case "XBOX_360":
            return PlatformIdDisplayName.XBOX_360;
        case "XBOX_ONE":
            return PlatformIdDisplayName.XBOX_ONE;
        case "mock":
            return PlatformIdDisplayName.MOCK;
        case "TIZEN":
            return PlatformIdDisplayName.TIZEN;
        case "NINTENDO":
        case "nintendo":
            return PlatformIdDisplayName.NINTENDO;
        case "OCULUS":
        case "oculus":
            return PlatformIdDisplayName.OCULUS;
        default:
            return PlatformIdDisplayName.UNKNOWN;
    }
};

export const getPlatformColor = (value: PlatformIdDisplayName): string | null => {
    switch (value) {
        case PlatformIdDisplayName.AMAZON:
            return "aqua";
        case PlatformIdDisplayName.ANG:
        case PlatformIdDisplayName.GOOGLE_PLAY:
            return "green";
        case PlatformIdDisplayName.APPLE_TV_OS:
        case PlatformIdDisplayName.I_TUNES:
        case PlatformIdDisplayName.WATCH_OS:
        case PlatformIdDisplayName.IOS:
        case PlatformIdDisplayName.MAC:
            return "red";
        case PlatformIdDisplayName.APP_WORLD:
        case PlatformIdDisplayName.BB:
            return "grey";
        case PlatformIdDisplayName.FB:
        case PlatformIdDisplayName.OCULUS:
            return "blue";
        case PlatformIdDisplayName.LINUX:
        case PlatformIdDisplayName.TIZEN:
            return "brown";
        case PlatformIdDisplayName.PS3:
        case PlatformIdDisplayName.PS4:
        case PlatformIdDisplayName.PS_VITA:
            return "lightblue";
        case PlatformIdDisplayName.ROKU:
            return "olive";
        case PlatformIdDisplayName.STEAM:
            return "yellow";
        case PlatformIdDisplayName.WEB:
            return "blue";
        case PlatformIdDisplayName.WII:
        case PlatformIdDisplayName.NINTENDO:
            return "pink";
        case PlatformIdDisplayName.WINDOWS:
        case PlatformIdDisplayName.WINP:
            return "purple";
        case PlatformIdDisplayName.WINP8:
            return "magenta";
        case PlatformIdDisplayName.XBOX_360:
        case PlatformIdDisplayName.XBOX_ONE:
            return "orange";
        case PlatformIdDisplayName.MOCK:
            return "brown";

        default:
            return null;
    }
};
