// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import QRCode from "qrcode";

// Import types.
import TwoFactorType from "types/enums/TwoFactorType";

// Import components.
import { Typography, Link, Divider } from "@mui/material";
import FieldWrapper from "components/common/form/FieldWrapper";
import ImageWrapper from "components/common/ImageWrapper";
import InfoBox from "components/common/screen/InfoBox";

// Import icons
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";

// Define the properties accepted by this component.
interface OWN_PROPS {
    type: TwoFactorType | null;
    step: "init" | "setup" | "tip" | "manage" | null;
    countryCode: string | null;
    phoneNumber: string | null;
    qrCodeUri: string | null;
    token: string | null;
    showAuthyWarning: boolean;
    onChange: (countryCode: string | null, phoneNumber: string | null, qrCodeUri: string | null, token: string | null) => void;
    onKeyPress: (name: string, key: string) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class TwoFactorAuthentication extends React.PureComponent<PROPS> {
    componentDidMount(): void {
        if (this.props.step === "manage") {
            if (this.props.type === TwoFactorType.VERIFY && this.props.qrCodeUri) {
                const canvas = document.getElementById("qrCodeCanvas");

                QRCode.toCanvas(canvas, this.props.qrCodeUri, (error) => {
                    if (error) console.error(error);
                });
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<PROPS>): void {
        if ((prevProps.step === "setup" && this.props.step === "manage") || prevProps.qrCodeUri !== this.props.qrCodeUri) {
            if (this.props.type === TwoFactorType.VERIFY && this.props.qrCodeUri) {
                const canvas = document.getElementById("qrCodeCanvas");

                QRCode.toCanvas(canvas, this.props.qrCodeUri, (error) => {
                    if (error) console.error(error);
                });
            }
        }
    }

    handleChange = (name: string, value: any) => {
        const { countryCode, phoneNumber, qrCodeUri, token, onChange } = this.props;

        switch (name) {
            case "countryCode":
                onChange(value, phoneNumber, qrCodeUri, token);
                break;
            case "phoneNumber":
                onChange(countryCode, value, qrCodeUri, token);
                break;
            case "qrCodeUri":
                onChange(countryCode, phoneNumber, value, token);
                break;
            case "token":
                onChange(countryCode, phoneNumber, qrCodeUri, value);
                break;
            default:
            // Unknown field.
        }
    };

    render() {
        const { classes, type, step, countryCode, phoneNumber, token, showAuthyWarning } = this.props;

        if (!type) return null;

        return (
            <div className={classes.root}>
                {step === "init" && (
                    <div className={classes.columnBlock}>
                        <Typography style={{ textAlign: "center" }}>
                            <Trans>Multi-Factor Authentication (MFA) keeps intruders out of your account by requiring both your password and phone to login.</Trans>
                        </Typography>

                        {type === TwoFactorType.VERIFY && (
                            <>
                                <ImageWrapper style={{ width: 110, margin: "auto" }} src={"/images/verify_step1.png"} />
                            </>
                        )}

                        {type === TwoFactorType.AUTHY && (
                            <>
                                <ImageWrapper style={{ width: 110, margin: "auto" }} src={"/images/authy_step1.png"} />
                            </>
                        )}

                        <Link data-id={"learn-more"} href={"https://docs.braincloudservers.com/learn/portal-tutorials/two-factor-authentication-for-team-members/"} target={"_blank"} style={{ textAlign: "center" }}>
                            <Trans>More information about MFA</Trans>
                        </Link>
                    </div>
                )}

                {step === "setup" && (
                    <div className={classes.columnBlock}>
                        <Typography style={{ fontWeight: "bold" }}>
                            <Trans>
                                Step {1} of {2}: {type}
                            </Trans>
                        </Typography>

                        {type === TwoFactorType.VERIFY && (
                            <>
                                <Typography>
                                    <Trans>To setup MFA, first click the (Link) button to generate a QR code that can be scanned by an authenticator app on your phone.</Trans>
                                </Typography>
                            </>
                        )}

                        {type === TwoFactorType.AUTHY && (
                            <>
                                <Typography>
                                    <Trans>To setup MFA, first enter your mobile phone number and click the (Link) button.</Trans>
                                </Typography>

                                <FieldWrapper type={"text"} name={"countryCode"} value={countryCode} label={<Trans>Country Code:</Trans>} labelPosition={"top"} labelAlignment={"flex-start"} onChange={this.handleChange} />

                                <FieldWrapper
                                    type={"text"}
                                    name={"phoneNumber"}
                                    value={phoneNumber}
                                    label={<Trans>Phone Number:</Trans>}
                                    labelPosition={"top"}
                                    labelAlignment={"flex-start"}
                                    onChange={this.handleChange}
                                    onKeyPress={this.props.onKeyPress}
                                />
                            </>
                        )}
                    </div>
                )}

                {step === "manage" && (
                    <div className={classes.columnBlock}>
                        <Typography style={{ fontWeight: "bold" }}>
                            <Trans>
                                Step {2} of {2}:
                            </Trans>
                        </Typography>

                        {type === TwoFactorType.VERIFY && (
                            <>
                                <Typography>
                                    <Trans>Scan the QR code, then enter the token from your MFA app below.</Trans>
                                </Typography>

                                <div id="qrCodeCanvasWrapper" style={{ flex: "0 0 auto", display: "flex", borderStyle: "solid", borderRadius: "0.2em", borderColor: "inherit", overflow: "hidden" }}>
                                    <canvas id={"qrCodeCanvas"} style={{ maxHeight: "10em", maxWidth: "10em", margin: "auto" }}></canvas>
                                </div>
                            </>
                        )}

                        {type === TwoFactorType.AUTHY && (
                            <>
                                <ImageWrapper style={{ width: 110, margin: "auto" }} src={"/images/authy_step2.png"} />

                                <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
                                    <Trans>Check your phone, it should have received a text message, or a push notification if you have the Authy app installed.</Trans>
                                </Typography>

                                <Typography>
                                    <Trans>If you didn't receive a message, click the (Resend SMS) button.</Trans>
                                </Typography>
                            </>
                        )}

                        <FieldWrapper type={"text"} name={"token"} value={token} label={<Trans>Token:</Trans>} labelPosition={"top"} labelAlignment={"flex-start"} onChange={this.handleChange} onKeyPress={this.props.onKeyPress} />
                    </div>
                )}

                {step === "tip" && (
                    <div className={classes.columnBlock}>
                        {showAuthyWarning && (
                            <>
                                <InfoBox
                                    type="warning"
                                    content={
                                        <span style={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
                                            <Typography style={{ fontWeight: "bold" }}>
                                                <Trans>WARNING: Access to the Twilio - Authy API will be discontinued on May 1, 2023 at which point any requests made will automatically receive an error.</Trans>
                                            </Typography>

                                            <Typography style={{ fontWeight: "bold", marginTop: "1em" }}>
                                                <Trans>RECOMMENDED: Migrate to the new Twilio - Verify API in order to keep you account secure.</Trans>
                                            </Typography>
                                        </span>
                                    }
                                />

                                <Divider className={classes.divider} />
                            </>
                        )}

                        <div className={classes.rowBlock} style={{ alignItems: "center" }}>
                            <div style={{ flex: "0 0 auto", width: 100, height: 100, padding: "0.625em" }}>
                                <SuccessIcon style={{ width: "100%", height: "100%", color: "var(--label-positive-color, inherit)" }} />
                            </div>

                            <div className={classes.columnBlock} style={{ flex: "1 1 auto" }}>
                                <Typography>
                                    <Trans>Multi-Factor Authentication (MFA) is enabled for this account.</Trans>
                                </Typography>

                                {type === TwoFactorType.AUTHY && (
                                    <Typography style={{ marginTop: "0.3125em", alignSelf: "flex-end" }}>
                                        <Trans>Linked to: {<b>{phoneNumber}</b>}</Trans>
                                    </Typography>
                                )}
                            </div>
                        </div>

                        <Divider className={classes.divider} />

                        {type === TwoFactorType.AUTHY && (
                            <span className={classes.rowBlock} style={{ alignItems: "center" }}>
                                <ImageWrapper style={{ width: 100, height: 100 }} src={"/images/authy.png"} />

                                <div className={classes.columnBlock} style={{ flex: "1 1 auto" }}>
                                    <Typography style={{ fontStyle: "italic" }}>
                                        <Trans>Pro tip: Download the Authy App to avoid expensive text messages!</Trans>
                                    </Typography>

                                    <Link data-id={"download-authy"} href={"https://www.authy.com/app/mobile/"} target={"_blank"} style={{ alignSelf: "flex-end" }}>
                                        <Trans>Download from authy.com</Trans>
                                    </Link>
                                </div>
                            </span>
                        )}

                        <Link data-id={"learn-more"} href={"https://docs.braincloudservers.com/learn/portal-tutorials/two-factor-authentication-for-team-members/"} target={"_blank"} style={{ marginTop: "0.625em", textAlign: "center" }}>
                            <Trans>More information about MFA</Trans>
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        rowBlock: {
            flex: "0 0 auto",
            display: "flex",
            margin: "0.3125em",
            flexDirection: "row",
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        columnBlock: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            margin: "0.3125em",
            gap: "0.3125em",
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        divider: {
            backgroundColor: "var(--navigation-border-color, inherit)",
        },
    });

export default withStyles(styles)(TwoFactorAuthentication);
