// Import libraries.
import { AbstractSearchPlugin } from "..";

// Import types.
import Session from "types/common/Session";
import PlayerInfo, { processPlayerInfo } from "types/models/PlayerInfo";
import { PluginName, Query, SearchConfiguration, SearchResult } from "../../types";

// Import utilities.
import Http from "utils/networking/Http";
import { PLAYER_EMAIL_HINTS, PLAYER_ID_HINTS } from "../../utils";

// Hints that are supported by this plugin.
const EXTERNAL_ID_HINTS = ["externalid"];

// A union of all valid hints.
const VALID_HINTS = [...PLAYER_ID_HINTS, ...PLAYER_EMAIL_HINTS, ...EXTERNAL_ID_HINTS];

/**
 * This plugin looks for results that are player-oriented.
 *
 * This includes all of the user monitoring screens under the APP context (based on the nature of the individual screen).
 */
class PlayerPlugin extends AbstractSearchPlugin {
    private session: Session | null = null;

    isHintSupported = (query: Query): boolean => {
        return VALID_HINTS.includes(query.hint.toLowerCase());
    };

    configure = (args?: SearchConfiguration | null) => {
        if (args) {
            this.session = args.session || null;
        }
    };

    executeQuery = async (query: Query, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        console.debug("Executing Plugin", PluginName.PLAYER, query);

        // Define a collection to hold the results.
        const searchResults: SearchResult[] = [];

        // Extract the hint and searchTerm from the query.
        const { hint, subHint, searchTerm } = query;

        // By default we have no list of players (since there could be millions).
        // This would be used by default or as a fallback if more specialized methods of determnining the list of players are unavailable (or fail).
        let players: PlayerInfo[] = [];

        if (PLAYER_ID_HINTS.includes(hint.toLowerCase())) {
            const playerResponse = await Http.GET("admin/monitoring/playerSummaryByPlayerId", { playerId: searchTerm }, Http.JSON_HEADERS, abortSignal);

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            }

            if (Http.isStatusOk(playerResponse) && !Http.isRedirect(playerResponse)) {
                players = (playerResponse.data != null && Object.keys(playerResponse.data).length > 0 ? [playerResponse.data] : []).map(processPlayerInfo);
            }
        } else if (PLAYER_EMAIL_HINTS.includes(hint.toLowerCase())) {
            const playerResponse = await Http.GET("admin/monitoring/findPlayersByEmailAddress", { emailAddress: searchTerm }, Http.JSON_HEADERS, abortSignal);

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            }

            if (Http.isStatusOk(playerResponse) && !Http.isRedirect(playerResponse) && Array.isArray(playerResponse.data?.players)) {
                players = playerResponse.data.players.map(processPlayerInfo);
            }
        } else if (EXTERNAL_ID_HINTS.includes(hint.toLowerCase())) {
            if (subHint != null) {
                // First fetch the list of defined external auth types.
                const externalAuthConfigsResponse = await Http.GET("admin/design/read-external-auth-configs", undefined, Http.JSON_HEADERS, abortSignal);

                if (abortSignal?.aborted) {
                    throw new DOMException("Aborted", "AbortError");
                }

                if (Http.isStatusOk(externalAuthConfigsResponse) && !Http.isRedirect(externalAuthConfigsResponse) && Array.isArray(externalAuthConfigsResponse.data)) {
                    const externalAuthTypes = externalAuthConfigsResponse.data.map((item) => item.name);

                    if (externalAuthTypes.includes(subHint)) {
                        const playerResponse = await Http.GET("admin/monitoring/findplayerByExternalId", { authType: subHint, externalId: searchTerm }, Http.JSON_HEADERS, abortSignal);

                        if (abortSignal?.aborted) {
                            throw new DOMException("Aborted", "AbortError");
                        }

                        if (Http.isStatusOk(playerResponse) && !Http.isRedirect(playerResponse) && Array.isArray(playerResponse.data?.players)) {
                            players = playerResponse.data.players.map(processPlayerInfo);
                        }
                    }
                }
            }
        } else {
            // Do nothing.
        }

        // Filter to applicable players (based on the hint and searchTerm supplied).
        // NOTE: The nature of the various server end-points remove the need to actually filter them here (they are always in the context of the currently selected app/game).
        const applicablePlayers = players;

        // Sort thge applicable apps by name.
        applicablePlayers.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

        // Generate SearchResult's for each applicable player.
        applicablePlayers.forEach((player) => {
            // APP / Monitoring / User Monitoring / User Browser.
            searchResults.push({
                type: PluginName.PLAYER,
                targetPath: "/user/user-browser",
                targetState: {
                    companyId: this.session?.companyIdAlias || this.session?.companyId,
                    appId: this.session?.appId,
                    playerId: player.playerId,
                },
                searchTerm: searchTerm,
                data: {
                    companyId: this.session?.companyIdAlias || this.session?.companyId,
                    appId: this.session?.appId,
                    playerId: player.playerId,
                    playerName: player.name,
                    lastLogin: player.lastLogin,
                    pictureUrl: player.pictureUrl,
                },
            });
        });

        console.debug("Plugin Results", PluginName.PLAYER, searchResults);

        return searchResults;
    };
}

export default PlayerPlugin;
