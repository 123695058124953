import { ThemeOptions } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const defaultMuiTheme: ThemeOptions = {
    palette: {},
    components: {
        // Surfaces.
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "var(--secondary-background-color, inherit)",
                    color: "var(--secondary-color, inherit)",
                    borderColor: "var(--secondary-border-color, inherit)",

                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "column",

                    "&.MuiAutocomplete-paper": {
                        fontFamily: "inherit",

                        backgroundColor: "var(--popup-menu-background-color, inherit)",
                        color: "var(--popup-menu-color, inherit)",
                        borderColor: "var(--popup-menu-border-color, inherit)",

                        borderStyle: "solid",
                        borderWidth: "0.0625em",
                        borderRadius: "0.25em",

                        boxShadow: "var(--standard-box-shadow)",

                        "& > div .MuiAutocomplete-listbox *.MuiAutocomplete-option": {
                            borderColor: "inherit",

                            "&[aria-selected='true']": {
                                backgroundColor: "var(--popup-menu-active-background-color, inherit)",
                                color: "var(--popup-menu-active-color, inherit)",
                            },
                            "&:not([aria-selected='true']):hover": {
                                backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                                color: "var(--popup-menu-hover-color, inherit)",
                            },
                        },

                        "& > div.MuiAutocomplete-noOptions": {
                            backgroundColor: "var(--popup-menu-background-color, inherit)",
                            color: "var(--popup-menu-color, inherit)",
                        },
                    },

                    "&.MuiPopover-paper": {
                        fontFamily: "inherit",

                        backgroundColor: "var(--popup-menu-background-color, inherit)",
                        color: "var(--popup-menu-color, inherit)",
                        borderColor: "var(--popup-menu-border-color, inherit)",

                        borderStyle: "solid",
                        borderWidth: "0.0625em",
                        borderRadius: "0.25em",

                        boxShadow: "var(--standard-box-shadow)",

                        "& > .MuiList-root, & > .MuiMenu-list": {
                            backgroundColor: "inherit",
                            color: "inherit",
                            borderColor: "inherit",
                        },

                        "& > .MuiMenu-list > .MuiMenuItem-root": {
                            backgroundColor: "inherit",
                            color: "inherit",
                            borderColor: "inherit",

                            "&.Mui-selected": {
                                backgroundColor: "var(--popup-menu-active-background-color, inherit)",
                                color: "var(--popup-menu-active-color, inherit)",
                            },

                            "&:not(.Mui-selected):hover": {
                                backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                                color: "var(--popup-menu-hover-color, inherit)",
                            },
                        },
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "var(--secondary-background-color, inherit)",
                    color: "var(--secondary-color, inherit)",
                    borderColor: "var(--secondary-border-color, inherit)",

                    flex: "0 0 auto",
                    display: "flex",
                    flexDirection: "column",

                    padding: 0,
                    margin: "0.3125em",
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                    padding: "0.3125em",
                    margin: 0,
                    maxWidth: "unset",
                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                },
                maxWidthLg: {
                    maxWidth: "unset",
                    padding: "0.3125em",
                    margin: 0,
                },
                maxWidthMd: {
                    maxWidth: "unset",
                    padding: "0.3125em",
                    margin: 0,
                },
                maxWidthSm: {
                    maxWidth: "unset",
                    padding: "0.3125em",
                    margin: 0,
                },
                maxWidthXl: {
                    maxWidth: "unset",
                    padding: "0.3125em",
                    margin: 0,
                },
                maxWidthXs: {
                    maxWidth: "unset",
                    padding: "0.3125em",
                    margin: 0,
                },
            },
        },
        // Tabs
        MuiTabs: {
            styleOverrides: {
                root: {
                    fontFamily: "inherit",
                    lineHeight: "inherit",
                    minHeight: "2em",
                    backgroundColor: "var(--tabs-background-color, inherit)",
                    color: "var(--tabs-color, inherit)",
                    borderColor: "inherit",
                },
                indicator: {
                    backgroundColor: "var(--tabs-active-border-color, inherit)",
                    width: "var(--tabs-active-indicator-size, 0.25em)",
                    height: "var(--tabs-active-indicator-size, 0.25em)",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: "inherit",
                    lineHeight: "inherit",

                    minHeight: "2em",
                    padding: "6px 12px",
                    opacity: 0.7,

                    backgroundColor: "var(--tabs-background-color, inherit)",
                    color: "var(--tabs-color, inherit)",
                    borderColor: "inherit",

                    "&:not(.Mui-selected):not(.Mui-disabled):hover": {
                        backgroundColor: "var(--tabs-hover-background-color, inherit)",
                        color: "var(--tabs-hover-color, inherit)",
                    },

                    "&.Mui-selected": {
                        backgroundColor: "var(--tabs-active-background-color, inherit)",
                        color: "var(--tabs-active-color, inherit)",

                        opacity: 1,
                    },

                    "&.Mui-disabled": {
                        backgroundColor: "var(--tabs-disabled-background-color, inherit)",
                        color: "var(--tabs-disabled-color, inherit)",
                    },
                },
            },
        },

        // Buttons
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: "1.5em",
                    minHeight: "1.5em",

                    display: "flex",
                    alignItems: "center",

                    margin: "0.125em",

                    fontSize: "0.875em",
                    fontFamily: "inherit",
                    lineHeight: "inherit",
                },
                startIcon: {
                    width: "1.5em",
                    height: "1.5em",

                    margin: "0 0.125em 0 0",

                    fontSize: "1em",

                    "& > svg": {
                        fontSize: "1em !important",

                        width: "1em !important",
                        height: "1em !important",

                        margin: "auto",

                        "&.MuiSvgIcon-root": {
                            width: "1.25em !important",
                            height: "1.25em !important",
                        },
                    },
                },
                endIcon: {
                    width: "1.5em",
                    height: "1.5em",

                    margin: "0 0 0 0.125em",

                    fontSize: "1em",

                    "& > svg": {
                        fontSize: "1em !important",

                        width: "1em !important",
                        height: "1em !important",

                        margin: "auto",

                        "&.MuiSvgIcon-root": {
                            width: "1.25em !important",
                            height: "1.25em !important",
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    width: "1.5em",
                    height: "1.5em",

                    margin: "0.125em",
                    padding: "0.125em",

                    fontSize: "1em",

                    "& > span": {
                        display: "flex",

                        width: "100%",
                        height: "100%",

                        "& > svg": {
                            fontSize: "1em",

                            width: "calc(100% - 0.25em)",
                            height: "calc(100% - 0.25em)",

                            margin: "auto",

                            "&.MuiSvgIcon-root": {
                                width: "100%",
                                height: "100%",
                            },
                        },
                    },

                    "& > svg": {
                        fontSize: "1em",

                        width: "calc(100% - 0.25em)",
                        height: "calc(100% - 0.25em)",

                        margin: "auto",

                        "&.MuiSvgIcon-root": {
                            width: "100%",
                            height: "100%",
                        },
                    },
                },
            },
        },

        // Links
        MuiLink: {
            defaultProps: {
                fontFamily: "inherit",
                lineHeight: "inherit",

                underline: "hover",
                color: "var(--link-color, inherit)",
            },
            styleOverrides: {
                root: {
                    cursor: "pointer",
                },
            },
        },

        // Menus
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "inherit",
                    lineHeight: "inherit",

                    textAlign: "left",
                },
            },
        },

        // Lists
        MuiListItem: {
            styleOverrides: {
                root: {
                    fontFamily: "inherit",
                    lineHeight: "inherit",
                },
            },
        },

        // Typographys
        MuiTypography: {
            defaultProps: {
                fontFamily: "inherit",
                lineHeight: "inherit",
            },
        },

        // Chips
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: "inherit",
                    lineHeight: "inherit",

                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
                deleteIcon: {
                    color: "inherit",
                    "&:hover": {
                        color: "inherit",
                        opacity: 0.8,
                    },
                },
            },
        },

        // Tooltips
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    pointerEvents: "none",
                },
                tooltip: {
                    fontFamily: "inherit",
                    lineHeight: "inherit",
                    fontSize: "0.875rem",

                    backgroundColor: "var(--tooltip-background-color)",
                    color: "var(--tooltip-color)",
                    border: "0.0625em solid var(--tooltip-border-color)",

                    "&.MuiTooltip-tooltipPlacementBottom, &.MuiTooltip-tooltipPlacementTop": {
                        marginTop: "0.25em !important",
                        marginBottom: "0.25em !important",
                    },

                    "&.error": {
                        backgroundColor: "var(--field-error-background-color)",
                        color: "var(--field-error-color)",
                        border: "0.0625em solid var(--field-error-border-color)",
                    },
                },
                arrow: {
                    color: "var(--tooltip-border-color)",

                    "&.error": {
                        color: "var(--field-error-border-color)",
                    },
                },
            },
        },

        // Dividers
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "inherit",
                },
            },
        },

        // Accordions
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                square: true,
            },
            styleOverrides: {
                root: {
                    boxShadow: "none",

                    "&:before": {
                        backgroundColor: "transparent",
                    },

                    "& > .MuiCollapse-root": {
                        backgroundColor: "inherit",
                        color: "inherit",
                        borderColor: "inherit",

                        "& > .MuiCollapse-wrapper": {
                            backgroundColor: "inherit",
                            color: "inherit",
                            borderColor: "inherit",

                            "& > .MuiCollapse-wrapperInner": {
                                backgroundColor: "inherit",
                                color: "inherit",
                                borderColor: "inherit",
                            },
                        },
                    },
                },

                region: {
                    display: "flex",

                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",

                    padding: "0.3125em",

                    minHeight: "var(--field-height)",
                },
                content: {
                    margin: 0,
                },
                expandIconWrapper: {
                    color: "inherit",
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    width: "100%",
                    height: "100%",

                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",

                    padding: 0,
                },
            },
        },

        /* Form Fields */
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minHeight: "unset",
                    height: "var(--field-height)",
                    maxHeight: "unset",

                    fontFamily: "inherit",
                    lineHeight: "inherit",

                    backgroundColor: "var(--field-background-color)",
                    color: "var(--field-color)",
                    borderColor: "var(--field-border-color)",

                    "& fieldset": {
                        borderColor: "inherit !important",
                    },

                    "&.MuiOutlinedInput-root": {
                        padding: 0,
                        overflow: "hidden",
                        "& > fieldset": {
                            top: 0,
                            "& > legend": {
                                display: "none",
                            },
                        },
                    },

                    overflow: "visible",

                    "&.Mui-error": {
                        backgroundColor: "var(--field-error-background-color)",
                        color: "var(--field-error-color)",
                        borderColor: "var(--field-error-border-color)",
                    },

                    "&.Mui-focused": {
                        backgroundColor: "var(--field-focused-background-color)",
                        color: "var(--field-focused-color)",
                        borderColor: "var(--field-focused-border-color)",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "var(--field-disabled-background-color)",
                        color: "var(--field-disabled-color)",
                        borderColor: "var(--field-disabled-border-color)",

                        cursor: "not-allowed",

                        WebkitTextFillColor: "var(--field-disabled-color)",
                    },
                },
                input: {
                    padding: "0.0625em 0.875em 0.0625em 0.875em !important",

                    "&.MuiSelect-select": {
                        padding: "0.0625em 2em 0.0625em 0.875em !important",
                    },

                    cursor: "inherit",

                    "&:-webkit-autofill": {
                        height: "inherit",
                    },

                    "&[type=number]": {
                        MozAppearance: "textfield",
                    },

                    "&[type=number]::-webkit-outer-spin-button, &[type=number]::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                    },

                    "&.Mui-disabled": {
                        WebkitTextFillColor: "unset",
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    width: "var(--field-height)",
                    height: "var(--field-height)",

                    color: "var(--field-color)",

                    cursor: "pointer",

                    padding: "0.375em",

                    "& > .MuiSvgIcon-root": {
                        width: "100%",
                        height: "100%",
                    },

                    "&.Mui-disabled": {
                        color: "var(--field-disabled-color)",

                        cursor: "not-allowed",

                        pointerEvents: "unset",
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    height: "var(--field-height)",
                    width: "calc(var(--field-height) * 2)",

                    padding: 0,

                    "&.Mui-focused > .MuiSwitch-track": {
                        backgroundColor: "var(--field-focused-background-color)",
                        borderColor: "var(--field-focused-border-color)",
                    },
                },
                switchBase: {
                    height: "var(--field-height)",
                    padding: 0,

                    transform: "translateX(0.3em)",

                    "&.Mui-disabled + .MuiSwitch-track": {
                        opacity: 1,

                        cursor: "not-allowed",
                    },

                    "&.Mui-checked": {
                        transform: "translateX(calc((var(--field-height) + 0.5em) - 0.3em))",
                    },
                },
                track: {
                    opacity: 1,

                    backgroundColor: "var(--field-background-color)",
                    borderColor: "var(--field-border-color)",
                    borderStyle: "solid",
                    borderRadius: "calc(var(--field-height) / 2)",
                },
                input: {
                    left: "-200%",
                    width: "600%",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: "inherit",
                    "&.Mui-disabled": {
                        color: "inherit",
                    },
                },
                select: {
                    userSelect: "auto",
                    cursor: "pointer",
                    "&.Mui-disabled": {
                        cursor: "not-allowed",
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    fontSize: "inherit",
                    height: "var(--field-height)",
                    width: "var(--field-height)",
                    "& .MuiSvgIcon-root": {
                        fontSize: "inherit",
                    },

                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",

                    "&.Mui-disabled": {
                        backgroundColor: "inherit",
                        color: "inherit",
                        borderColor: "inherit",
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                endAdornment: {
                    "& .MuiIconButton-root": {
                        color: "inherit",
                    },
                },
                popupIndicator: {
                    "&.Mui-disabled": {
                        color: "inherit",
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0,
                    "&.Mui-disabled": {
                        backgroundColor: "inherit",
                        color: "inherit",
                        borderColor: "inherit",

                        cursor: "not-allowed",
                        opacity: 0.3,
                    },
                },
                label: {
                    flex: "1 1 auto",

                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",

                    "&.Mui-disabled": {
                        backgroundColor: "inherit",
                        color: "inherit",
                        borderColor: "inherit",
                    },
                },
            },
        },

        /** Table Styling **/
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    userSelect: "auto",
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& th.MuiTableCell-root.MuiTableCell-stickyHeader": {
                        backgroundColor: "inherit",
                        color: "inherit",
                        borderColor: "inherit",
                        fontWeight: "bold",
                    },
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    "&:hover, &.Mui-active": {
                        color: "inherit",
                    },
                    "&.Mui-active .MuiTableSortLabel-icon": { color: "inherit" },
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    "& td.MuiTableCell-stickyHeader": {
                        backgroundColor: "inherit",
                        color: "inherit",
                        borderColor: "inherit",
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                    "&.MuiTableRow-hover:hover": {
                        backgroundColor: "inherit",
                        color: "inherit",
                        borderColor: "inherit",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "inherit",
                    fontSize: "1em",
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                    overflow: "hidden",
                },
                body: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                    borderTopWidth: "0.0625em",
                    borderTopStyle: "solid",
                },
                selectRoot: {
                    borderRadius: "0.25em",
                    overflow: "hidden",
                },
                input: {
                    border: "0.0625em solid",
                    backgroundColor: "var(--field-background-color, inherit)",
                    color: "var(--field-color, inherit)",
                    borderColor: "var(--field-border-color, inherit)",
                    "&.Mui-disabled": {
                        backgroundColor: "var(--field-disabled-background-color, inherit)",
                        color: "var(--field-disabled-color, inherit)",
                        borderColor: "var(--field-disabled-border-color, inherit)",
                    },
                    "&.Mui-focused:not(.Mui-disabled)": {
                        backgroundColor: "var(--field-focused-background-color, inherit)",
                        color: "var(--field-focused-color, inherit)",
                        borderColor: "var(--field-focused-border-color, inherit)",
                    },
                },
            },
        },

        // MUI 6 Date Time pickers
        MuiPickersPopper: {
            styleOverrides: {
                paper: {
                    backgroundColor: "var(--popup-menu-background-color, inherit)",
                    color: "var(--popup-menu-color, inherit)",
                    borderColor: "var(--popup-menu-border-color, inherit)",
                    borderStyle: "solid",
                    borderWidth: "0.0625em",
                    borderRadius: "0.25em",

                    boxShadow: "var(--standard-box-shadow)",

                    "& .MuiDivider-root": {
                        borderColor: "var(--popup-menu-border-color, inherit)",
                    },
                },
            },
        },
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    "& .MuiPickersLayout-actionBar": {
                        "& .MuiButton-root": {
                            backgroundColor: "var(--button-neutral-background-color, inherit)",
                            color: "var(--button-neutral-color, inherit)",
                            borderColor: "var(--button-neutral-border-color, inherit)",
                        },
                    },
                },
            },
        },
        MuiMultiSectionDigitalClockSection: {
            styleOverrides: {
                root: {
                    borderColor: "var(--popup-menu-border-color, inherit) !important",
                },
            },
        },
        MuiClock: {
            styleOverrides: {
                root: {
                    color: "inherit",
                },
                clock: {
                    backgroundColor: "inherit",
                    color: "inherit",
                },
                amButton: {
                    color: "inherit",
                },
                pmButton: {
                    color: "inherit",
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    color: "inherit",
                },
                switchViewButton: {
                    color: "inherit",
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                root: {
                    color: "inherit",
                },
                button: {
                    color: "inherit",

                    "&.Mui-disabled": {
                        color: "var(--field-disabled-color, inherit) !important",
                        opacity: 0.8,
                    },
                },
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    color: "inherit",
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    color: "inherit",
                    backgroundColor: "inherit",

                    "&:hover": {
                        color: "var(--popup-menu-hover-color, inherit)",
                        backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                    },

                    "&.Mui-disabled": {
                        color: "var(--field-disabled-color, inherit) !important",
                        opacity: 0.8,
                    },

                    "&:not(.Mui-selected)": {
                        borderColor: "var(--secondary-border-color, inherit)",
                    },
                },
            },
        },
        MuiClockNumber: {
            styleOverrides: {
                root: {
                    color: "inherit",
                    backgroundColor: "inherit",

                    "&:hover": {
                        color: "var(--popup-menu-hover-color, inherit)",
                        backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                    },

                    "&.Mui-disabled": {
                        color: "var(--field-disabled-color, inherit) !important",
                        opacity: 0.8,
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: "inherit",
                    margin: 0,
                    "& .MuiIconButton-root": {
                        color: "inherit",
                        margin: "0 -.25em 0 -.75em",
                    },
                },
            },
        },
    },
};

export default defaultMuiTheme;
