export default interface ProductInfo {
    absoluteImageUrl: string | null;
    category: string | null;
    createdAt: Date;
    currency: any;
    data: any;
    defaultPriceId: number;
    description: string | null;
    gameId: string;
    itunesSubscriptionType: string | null;
    imageUrl: string | null;
    itemId: string;
    items: { [key: string]: ProductItemDefinition };
    parentCurrency: any;
    peerCurrency: any;
    publishedItems: PublishedItem[];
    prices: ProductPriceInfo[];
    title: string;
    type: string;
    updatedAt: Date | null;
    version: number;
    hasRewards: boolean;
    imageTempObjectKey?: string;
}

export interface ProductPriceInfo {
    priceId: number;
    referencePrice: number;
    storeData: StoreData;
}

export interface StoreData {
    amazon?: { id: string };
    appworld?: { id: string; price: number };
    facebook?: { currencyList: ProductFacebookCurrency[] };
    googlePlay?: { id: string };
    itunes?: { ids: { appId: string; itunesId: string }[] };
    steam?: { itemId: number; prices: ProductSteamCurrency[] };
    windows?: { id: string };
    windowsPhone?: { id: string };
}

export interface ProductCurrency {
    currencyName: string;
    price: number;
}

export interface ProductFacebookCurrency {
    currency: string;
    price: number;
}

export interface ProductSteamCurrency {
    currency: string;
    price: number;
}

export interface ProductItemDefinition {
    defId: string;
    quantity: number;
}

export interface PublishedItem {
    label: string;
}

export interface ProductPurchasesCounts {
    mockNonSandbox: number;
    realNonSandbox: number;
    totalCounts: number;
}

export const processProductInfo = (data: any) => {
    if (data)
        return {
            absoluteImageUrl: data.absoluteImageUrl || null,
            category: data.category || null,
            createdAt: new Date(data.createdAt),
            currency: data.currency,
            data: data.data != null ? (typeof data.data === "string" ? data.data : JSON.stringify(data.data, undefined, 2)) : null,
            defaultPriceId: data.defaultPriceId,
            description: data.description || null,
            gameId: data.gameId,
            itunesSubscriptionType: data.iTunesSubscriptionType || null,
            imageUrl: data.imageUrl || null,
            itemId: data.itemId,
            items: data.items,
            parentCurrency: data.parentCurrency,
            peerCurrency: data.peerCurrency,
            publishedItems: processPublishedItems(data.publishedItems),
            prices: processProductPrices(data.prices),
            title: data.title,
            type: data.type,
            updatedAt: data.updatedAt != null ? new Date(data.updatedAt) : null,
            version: data.version,
            hasRewards: data.hasRewards,
        } as ProductInfo;
    else return null;
};

const processProductPrices = (data: any): ProductPriceInfo[] => {
    if (data && Array.isArray(data)) {
        return data.map((item) => ({
            priceId: item.priceId,
            referencePrice: item.referencePrice,
            storeData: processStoreData(item.storeData),
        }));
    }

    return [];
};

const processStoreData = (data: any): StoreData => {
    let storeData = data as StoreData;
    if (data.steam) {
        storeData.steam = processProductSteamCurrency(data.steam);
    }
    return storeData;
};

const processProductSteamCurrency = (data: any) => {
    let steamData = {} as { itemId: number; prices: { currency: string; price: number }[] };
    steamData.itemId = data.itemId;
    if (Array.isArray(data.prices)) {
        steamData.prices = data.prices.map((item: any) => ({
            currency: item.currency,
            price: (item.price / 100).toFixed(2),
        }));
    }

    return steamData;
};

const processPublishedItems = (data: any): PublishedItem[] => {
    if (data && Array.isArray(data)) {
        return data.map((item) => ({
            label: item.label,
        }));
    }

    return [];
};

export const processProductPurchacesCounts = (data: any): ProductPurchasesCounts => {
    return {
        mockNonSandbox: data.mockNonSandbox,
        realNonSandbox: data.realNonSandbox,
        totalCounts: data.mockNonSandbox + data.realNonSandbox,
    };
};
