// Import libraries.
import Http, { HttpResponse } from "utils/networking/Http";

// Import types.
import AppDailyCounts, { processAppDailyCounts } from "types/models/AppDailyCounts";
import AppAggregateCounts, { processAppAggregateCounts } from "types/models/AppAggregateCounts";
import ProductInfo, { processProductInfo } from "types/models/ProductInfo";

export const getAppDailyCounts = async (date?: Date, abortSignal?: AbortSignal): Promise<AppDailyCounts> => {
    const response: HttpResponse = await Http.GET("admin/reporting/dailyGameCounts-read", { dateInMillis: (date != null ? date : new Date()).getTime() }, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppDailyCounts(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppAggregateCounts = async (abortSignal?: AbortSignal): Promise<AppAggregateCounts> => {
    const response: HttpResponse = await Http.GET("admin/reporting/aggregateGameCounts-read", null, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppAggregateCounts(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppCurrentUserCount = async (abortSignal?: AbortSignal): Promise<number | null> => {
    const response: HttpResponse = await Http.GET("admin/reporting/getUpToDateUserCount", null, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data ? Number.parseInt(response.data.upToDateUserCount) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getProductItems = async (abortSignal?: AbortSignal): Promise<ProductInfo[]> => {
    const response: HttpResponse = await Http.GET("admin/development/productItems-read", null, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        const data: any[] = response && response.data && Array.isArray(response.data) ? response.data : [];

        return Promise.resolve(data.map((item) => processProductInfo(item) as ProductInfo));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    getAppDailyCounts,
    getAppAggregateCounts,
    getAppCurrentUserCount,
    getProductItems,
};

export default Services;
