import { Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import BrainCloudPropertiesService from "services/BrainCloudProperties";

const PassesRuleIcon: React.FC<{ passes: boolean }> = ({ passes }) => {
    if (passes) {
        return <CheckCircleOutlineIcon style={{ width: "0.8em", height: "0.8em", color: "var(--label-positive-color)" }} />;
    } else {
        return <CancelOutlinedIcon style={{ width: "0.8em", height: "0.8em", color: "var(--label-negative-color)" }} />;
    }
};

const PassesRuleItem: React.FC<React.PropsWithChildren<{ passes: boolean }>> = ({ passes, children }) => {
    return (
        <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center", marginTop: "0.3125em", marginBottom: "0.3125em" }}>
            <PassesRuleIcon passes={passes} />

            <Typography component={"span"} style={{ marginLeft: "0.5em", fontSize: "0.8em" }}>
                {children || "---"}
            </Typography>
        </span>
    );
};

interface PROPS {
    className?: string;
    style?: React.CSSProperties;

    password: string;

    onChange: (valid: boolean) => void;
}

export const PasswordEnforcementRules: React.FC<PROPS> = ({ className, style, password, onChange }) => {
    const [passwordEngorcementRules, setPasswordEnforcementRules] = useState<{
        isEnabled: boolean;
        minLength: number;
        maxLength: number;
        minDigits: number;
        minUppercase: number;
        minLowercase: number;
        minSymbols: number;
        validSymbols: string[];
    }>({
        isEnabled: false,
        minLength: 8,
        maxLength: 25,
        minDigits: 1,
        minUppercase: 1,
        minLowercase: 1,
        minSymbols: 1,
        validSymbols: [],
    });

    const [passesMinLengthCheck, setPassesMinLengthCheck] = useState(false);
    const [passesMaxLengthCheck, setPassesMaxLengthCheck] = useState(false);
    const [passesMinDigitsCheck, setPassesMinDigitsCheck] = useState(false);
    const [passesMinUppercaseCheck, setPassesMinUppercaseCheck] = useState(false);
    const [passesMinLowercaseCheck, setPassesMinLowercaseCheck] = useState(false);
    const [passesMinSymbolsCheck, setPassesMinSymbolsCheck] = useState(false);

    const [isValid, setIsValid] = useState(false);

    const loadPasswordEnforcementRules = useCallback(async () => {
        const [config, symbols] = await Promise.all([BrainCloudPropertiesService.getSystemProperty("portalPasswordEnforcement"), BrainCloudPropertiesService.getSystemProperty("passwordsSpecialSymbols")]);

        if (config) {
            setPasswordEnforcementRules({
                isEnabled: config.isEnabled === true,
                minLength: typeof config.minLength === "number" ? config.minLength : 8,
                maxLength: typeof config.maxLength === "number" ? config.maxLength : 25,
                minDigits: typeof config.minDigits === "number" ? config.minDigits : 1,
                minUppercase: typeof config.minUppercase === "number" ? config.minUppercase : 1,
                minLowercase: typeof config.minLowercase === "number" ? config.minLowercase : 1,
                minSymbols: typeof config.minSymbols === "number" ? config.minSymbols : 1,
                validSymbols: typeof symbols === "string" ? Array.from<any, string>({ length: symbols.length }, (_, i) => symbols.charAt(i)) : [],
            });
        }
    }, []);

    const validatePassword = useCallback(
        (password: string) => {
            if (passwordEngorcementRules.isEnabled) {
                let digitCount = 0;
                let uppercaseCount = 0;
                let lowercaseCount = 0;
                let symbolCount = 0;

                for (let x = 0; x < password.length; x++) {
                    const char = password.charAt(x);

                    if (/^\d$/.test(char)) digitCount++;
                    if (/^[A-Z]$/.test(char)) uppercaseCount++;
                    if (/^[a-z]$/.test(char)) lowercaseCount++;
                    if (passwordEngorcementRules.validSymbols.includes(char)) symbolCount++;
                }

                let valid = true;

                if (password.length >= passwordEngorcementRules.minLength) setPassesMinLengthCheck(true);
                else {
                    setPassesMinLengthCheck(false);
                    valid = false;
                }

                if (password.length <= passwordEngorcementRules.maxLength) setPassesMaxLengthCheck(true);
                else {
                    setPassesMaxLengthCheck(false);
                    valid = false;
                }

                if (digitCount >= passwordEngorcementRules.minDigits) setPassesMinDigitsCheck(true);
                else {
                    setPassesMinDigitsCheck(false);
                    valid = false;
                }

                if (uppercaseCount >= passwordEngorcementRules.minUppercase) setPassesMinUppercaseCheck(true);
                else {
                    setPassesMinUppercaseCheck(false);
                    valid = false;
                }

                if (lowercaseCount >= passwordEngorcementRules.minLowercase) setPassesMinLowercaseCheck(true);
                else {
                    setPassesMinLowercaseCheck(false);
                    valid = false;
                }

                if (symbolCount >= passwordEngorcementRules.minSymbols) setPassesMinSymbolsCheck(true);
                else {
                    setPassesMinSymbolsCheck(false);
                    valid = false;
                }

                setIsValid(valid);
            } else {
                setIsValid(true);
            }
        },
        [passwordEngorcementRules]
    );

    useEffect(() => {
        loadPasswordEnforcementRules();
    }, [loadPasswordEnforcementRules]);

    useEffect(() => {
        validatePassword(password);
    }, [password, validatePassword]);

    useEffect(() => {
        onChange(isValid);
    }, [isValid, onChange]);

    if (!passwordEngorcementRules.isEnabled) {
        return null;
    }

    return (
        <span
            className={className}
            style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "0.5em",
                borderLeftWidth: "0.625em",
                borderLeftStyle: "solid",
                borderLeftColor: "var(--infobox-info-border-color)",
                backgroundColor: "var(--infobox-info-background-color)",
                padding: "0.3125em 0.625em",
                overflow: "hidded",
                ...style,
            }}
        >
            <PassesRuleItem passes={passesMinLengthCheck && passesMaxLengthCheck}>
                {passwordEngorcementRules.minLength === passwordEngorcementRules.maxLength ? (
                    <Trans>Password is {passwordEngorcementRules.minLength} character(s) long</Trans>
                ) : (
                    <Trans>
                        Password is {passwordEngorcementRules.minLength} to {passwordEngorcementRules.maxLength} characters long
                    </Trans>
                )}
            </PassesRuleItem>

            <PassesRuleItem passes={passesMinDigitsCheck}>
                <Trans>Has at least {passwordEngorcementRules.minDigits} digit(s)</Trans>
            </PassesRuleItem>

            <PassesRuleItem passes={passesMinUppercaseCheck}>
                <Trans>Has at least {passwordEngorcementRules.minUppercase} uppercase letter(s)</Trans>
            </PassesRuleItem>

            <PassesRuleItem passes={passesMinLowercaseCheck}>
                <Trans>Has at least {passwordEngorcementRules.minLowercase} lowercase letter(s)</Trans>
            </PassesRuleItem>

            <PassesRuleItem passes={passesMinSymbolsCheck}>
                <Trans>Has at least {passwordEngorcementRules.minSymbols} symbol(s)</Trans>
            </PassesRuleItem>
        </span>
    );
};
