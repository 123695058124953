export default interface PlayerInfo {
    playerId: string;

    appId: string | null;
    appVersion: string | null;

    name: string | null;
    email: string | null;

    clientLib: string | null;
    clientLibVersion: string | null;

    platform: string | null;

    loginCount: number | null;

    createdAt: Date | null;

    lastLogin: Date | null;

    updatedAt: Date | null;

    isActive: boolean | null;

    notes: string | null;

    pictureUrl: string | null;

    amountSpent: number | null;

    badLoginAttempts: number;
    blocked: boolean;
    blockedUntil: Date | null;
}

export const processPlayerInfo = (data: any): PlayerInfo => {
    return {
        playerId: data?.playerId || "",

        appId: data?.gameId || null,
        appVersion: data?.appVersion || null,

        name: data?.name ? data.name : data?.playerName || null,
        email: data?.email ? data.email : data?.emailAddress || null,

        clientLib: data?.clientLib ? data.clientLib : null,
        clientLibVersion: data?.clientLibVersion ? data.clientLibVersion : null,

        platform: data?.platform || null,

        loginCount: data?.loginCount != null ? Number.parseInt(data.loginCount) : null,

        createdAt: data?.createdAt != null ? new Date(data.createdAt) : null,

        lastLogin: data?.lastLogin != null ? new Date(data.lastLogin) : null,

        updatedAt: data?.updatedAt != null ? new Date(data.updatedAt) : null,

        isActive: data?.isActive != null ? data.isActive : null,

        notes: data?.notes || null,

        pictureUrl: data?.pictureUrl || null,

        amountSpent: data?.amountSpent ? Number.parseInt(data.amountSpent) : data?.start?.amountSpent ? Number.parseInt(data.start.amountSpent) : 0,

        badLoginAttempts: data?.badLoginAttempts ? Number.parseInt(data.badLoginAttempts) : 0,
        blocked: data?.blocked === true,
        blockedUntil: data?.blockedUntilMillis != null ? new Date(data.blockedUntilMillis) : null,
    };
};
